/**
 *  created by lllwx
 *  Date: 2021/6/18
 *  Time: 11:51 上午
 *  Version: 1.0
 *  For: 注册需要使用的antd组件
 */
import type { App } from 'vue';

import {
  Button,
  Form,
  Upload,
  Progress,
  Steps,
  Tree,
  TreeSelect,
  Transfer,
  DatePicker,
  Modal,
  Breadcrumb,
  Tooltip,
  Input,
  InputNumber,
  InputPassword,
  Select,
  Pagination,
  Spin,
  Popconfirm,
  Table,
  Empty,
  Menu,
  Switch,
  Cascader,
  Image,
  Radio,
  Popover,
  List,
  Dropdown,
  Checkbox,
  Descriptions,
  Tag,
  Row,
  Col,
  Slider,
  Carousel,
} from 'ant-design-vue';

// @ts-nocheck

export const registerAntd = (app: App) => {
  app
    .use(Button)
    .use(Switch)
    .use(Form)
    .use(Upload)
    .use(Progress)
    .use(Steps)
    .use(Tree)
    .use(TreeSelect)
    .use(DatePicker)
    .use(Modal)
    .use(Breadcrumb)
    .use(Tooltip)
    .use(Dropdown)
    .use(Transfer)
    .use(Input)
    .use(InputPassword)
    .use(Input.TextArea)
    .use(InputNumber)
    .use(Select)
    .use(Pagination)
    .use(Popconfirm)
    .use(Table)
    .use(Empty)
    .use(Menu)
    .use(Cascader)
    .use(Spin)
    .use(Radio)
    .use(Image)
    .use(List)
    .use(Checkbox)
    .use(Descriptions)
    .use(Tag)
    .use(Row)
    .use(Col)
    .use(Popover)
    .use(Carousel)
    .use(Slider);
};
