/**
 *  created by lllwx
 *  Date: 2021/6/3
 *  Time: 1:55 下午
 *  Version: 1.0
 *  For: echarts 按需加载
 */
import {
  LineChart,
  LinesChart,
  BarChart,
  RadarChart,
  TreeChart,
  ScatterChart,
  EffectScatterChart,
  PieChart,
} from 'echarts/charts';
import {
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  GridComponent,
  VisualMapComponent,
  PolarComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { use } from 'echarts/core';

export const registerCharts = () => {
  use([
    LineChart,
    BarChart,
    RadarChart,
    TreeChart,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    GridComponent,
    VisualMapComponent,
    PolarComponent,
    CanvasRenderer,
    ScatterChart,
    EffectScatterChart,
    PieChart,
    LinesChart,
    DataZoomComponent,
  ]);
};
