/**
 *  created by lllwx
 *  @date: 2022/6/9
 *  @time: 14:23
 *  @version: 1.0
 *  @desc:
 */
import { createPinia } from 'pinia';
import type { App } from 'vue';

const store = createPinia();

export function setupStore(app:App<Element>) {
  app.use(store);
}

export { store };
