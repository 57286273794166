export const USER_INFO = 'tk_userinfo';
export const DICT = 'tk_dict';
export const TOKEN = 'tk_token';
export const AUTH = 'tk_auth';
export const TENANT = 'tk_tenant';
export const SITE = 'tk_site';
export const STUDY = 'tk_study';
export const ROLE = 'tk_role';
export const SELECT_LOCALE = 'tk_locale';

export const LANGUAGE_CN = 'zh-cn';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_AUTO = 'auto';
export const LANGUAGE_SETTING = 'tk_language_set';
