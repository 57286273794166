import type { I18n, I18nOptions } from 'vue-i18n';

// eslint-disable-next-line import/no-mutable-exports
export let i18n;

async function getGlobalMessage(locale): Promise<I18nOptions> {
  const defaultLocal = await import(`./lang/${locale}.ts`);
  return defaultLocal.default?.message ?? {};
}

export async function addGlobalI18n(appI18n: I18n, locale) {
  const globalMessage = await getGlobalMessage(locale);
  i18n = appI18n;
  // 添加全局i18n 文件
  i18n.global.messages.value[locale] = {
    ...i18n.global.messages.value[locale],
    ...globalMessage,
  };
}
