/**
 *  created by lllwx
 *  Date: 2021/6/1
 *  Time: 2:37 下午
 *  Version: 1.0
 *  For: table hooks
 */
import { useEventBus } from '@vueuse/core';
import {
  reactive, ref, Ref,
  toRefs, unref, watch,
} from 'vue';
import { pageSetting } from '../tools/constant';
import { TableMountedEvent } from '../tools/constant/key';

export interface IBaseOptions extends TKTable.ITableProps {
  pageSize: number;
  pageOptions: Partial<TKTable.Pagination>;
  form: any;
  // 是否立即调用请求
  lazyLoad: boolean;
  hasData: boolean;
  emptyText: string
  emptyRender?: any
  beforeFetch?: (params: any) => any
  customRow?:(record: any, index:number) => any
}

export interface IBasicTableOptions extends IBaseOptions {
  count: number;
  // 分页数量
  pageNumber: number;
  loading: boolean;
  dataSource: Record<string, any>[] | undefined
}

export declare type Query<T> = (...args: any) => any

export const scroll: { scrollX: string, scrollY: Ref<string> | null} = {
  scrollX: '',
  scrollY: null,
};

export const queryTableConfig: Partial<IBaseOptions> = {
};

export function useTableQuery<T>(query: Query<T>, options?: Partial<IBaseOptions>):
  { tableProps: IBasicTableOptions, search: { submit: () => void, reset: () => void, refetch: () => void }, pageLimit: Ref<{pageSize: number, pageNo: number}>, }

export function useTableQuery<T>(query: any | Partial<IBaseOptions>, options?: Partial<IBaseOptions>) {
  const baseOptions: IBaseOptions = {
    dataSource: [],
    pageSize: 10,
    bordered: true,
    pageOptions: {},
    pagination: true,
    form: null,
    lazyLoad: false,
    emptyText: '暂无数据',
    hasData: true,
    scroll: scroll.scrollX ? { x: scroll.scrollX } : {},
  };

  const tableProps = reactive<IBasicTableOptions>({
    ...baseOptions,
    ...queryTableConfig,
    ...(query instanceof Function ? {} : query),
    loading: false,
    dataSource: undefined,
    count: 0,
    ...(options ?? {}),
  });

  const bus = useEventBus(TableMountedEvent);
  bus.on(() => {
    // 如果需要y轴滚动
    if (scroll.scrollY) {
      tableProps.scroll = {
        ...tableProps.scroll,
        y: scroll.scrollY!,
      };
    }
  });

  const {
    form, pagination, beforeFetch, pageSize, lazyLoad,
  } = toRefs(tableProps);

  const basePage = 1;
  const pageLimit = ref({
    [pageSetting.pageNo]: basePage,
    [pageSetting.pageSize]: pageSize.value,
  });

  const params = form;
  // 如果有请求前需要加入的参数
  if (beforeFetch?.value) {
    watch(params.value, (value) => {
      params.value = beforeFetch.value!(params.value ?? {});
    });
  }

  const {
    isLoading, data, refetch,
  } = query(params, pagination?.value ? pageLimit.value : {});

  watch(data, (value) => {
    if (value) {
      const { result } = value;
      // 是否 分页数据
      if (result instanceof Array) {
        tableProps.hasData = !!result.length;
        tableProps.dataSource = result;
      } else {
        // 总条数为0 表示 没有数据
        tableProps.hasData = !!result.totalCount;
        tableProps.dataSource = result.records;
        tableProps.count = result.total;
        tableProps.pageNumber = result.pages;
      }
    }
  }, { immediate: true });

  tableProps.loading = isLoading;

  const search = {
    submit: () => { pageLimit.value[pageSetting.pageNo] = 1; refetch(); },
    reset: resetPageAndForm,
    refetch: () => { refetch(); },
  };

  // 修改分页长度 回到第一页
  watch(() => pageLimit.value[pageSetting.pageSize], () => {
    if (pageLimit.value[pageSetting.pageNo] !== 1) {
      pageLimit.value[pageSetting.pageNo] = 1;
    }
  });

  // 重置分页条件
  function resetPageLimit() {
    if (unref(pageLimit)[pageSetting.pageNo] === basePage && unref(pageLimit)[pageSetting.pageSize] === tableProps.pageSize) {
      refetch();
    } else {
      pageLimit.value[pageSetting.pageNo] = basePage;
      pageLimit.value[pageSetting.pageSize] = tableProps.pageSize;
    }
  }

  // 重置 分页参数
  function resetPageAndForm() {
    // 等待 表单参数重置 操作
    setTimeout(() => {
      // 如果有分页 需要重置分页参数
      tableProps.pagination ? resetPageLimit() : refetch();
    });
  }

  // 表格第一个参数是查询接口方法
  if (query instanceof Function) {
    return {
      search,
      tableProps,
      pageLimit,
    };
  }

  // 表格只有一个选项参数
  return {
    tableProps: reactive({
      ...tableProps,
      ...query,
    }),
  };
}
