// 激活状态
export const ACTIVE_STATUS = 1;

// Switch组件 默认值
export const SWITCH_CHECKED_PROPS = {
  checkedValue: 1,
  unCheckedValue: 0,
};

// 平台编号
export const PLATFORM = 'tenant';

// 版本号
export const APP_VERSION = '1.0.16';

export const HTML_TITLE = '';

// 统一上传地址
export const UPLOAD_URL = `${import.meta.env.VITE_BASE_URL}/meta/v1/sys/oss/file/upload`;
