export { DATE_TIME_FORMAT } from './date';

// 文件上传 图片类型
export const IMAGE_TYPE = '.png,.gif,.jpg';

// 文件上传 excel 类型
export const EXCEL_TYPE = '.xls,.xlsx';

// 文件上传 word 类型
export const WORD_TYPE = '.doc,.docx';

// 文件上传pdf类型
export const PDF_TYPE = '.pdf';

// 字典键值
export const DICT = 'tk_dict';

// 菜单标签栏
export const MENU_TABS = 'tk_menu_tabs';

// 菜单标签栏最多8个
export const MENU_TABS_MAX = 8;

// 分页参数名 配置
export const pageSetting = {
  pageSize: 'pageSize',
  pageNo: 'pageNo',
};

// Switch组件 默认值
export const SWITCH_CHECKED_PROPS = {
  checkedValue: 1,
  unCheckedValue: 0,
};
