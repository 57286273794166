import { Request } from '@/utils/request';

const request = Request.getInstance();

/**
 * Client Site Area Controller
 */
export class ClientSiteAreaAPI {
  /**
  * 获取帮助联系我们
  * @param {string} platformCode - 平台code
  * @param {number} siteAreaId - 院区id
  * @param {number} studyId - 试验id
  * @param {number} tenantId - 租户id
  */
  static getSiteSupportsUsingGET(params?: {
                            platformCode?:string,
                            siteAreaId?:number,
                            studyId?:number,
                            tenantId?:number,

                }) {
    return request.get<Array<TkApi.ClientSiteSupportVo>>('/client/v1/siteArea/getSiteSupports',
      params);
  }

  /**
  * 条件查询
  * @param {Array<number>} areaSiteIds - 院区id列表
  * @param {string} code - 编号
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} name - 名称
  * @param {string} orderBy - 排序字段
  * @param {number} projectId - 项目id
  * @param {string} protocolNo - 临床编号
  * @param {number} size - 一页几条
  * @param {number} sponsorId - 申办方id
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} studyId - 试验id
  * @param {number} tenantId - 租户id
  */
  static listUsingGET(params?: {
                            areaSiteIds?:Array<number>,
                            code?:string,
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            name?:string,
                            orderBy?:string,
                            projectId?:number,
                            protocolNo?:string,
                            size?:number,
                            sponsorId?:number,
                            status?:number,
                            studyId?:number,
                            tenantId?:number,

                }) {
    return request.get<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/list',
      params);
  }

  /**
  * 根据用户名和租户查询院区
  * @param {number} tenantId - tenantId
  * @param {string} username - username
  */
  static listByUsernameUsingGET(params?: {
                            tenantId:number,
                            username:string,

                }) {
    return request.get<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/listByUsername',
      params);
  }

  /**
  * list查询项目已绑定院区信息
  * @param {TkApi.ClientSiteAreaReq} ClientSiteAreaReq - clientSiteAreaReq
  */
  static listProjectBindingAreaSiteUsingPOST(
    ClientSiteAreaReq:TkApi.ClientSiteAreaReq,

  ) {
    return request.post<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/listProjectBindingAreaSite', ClientSiteAreaReq);
  }

  /**
  * 根据临床方案号查询项目已绑定院区信息
  * @param {TkApi.ClientSiteAreaReq} ClientSiteAreaReq - clientSiteAreaReq
  */
  static listProjectBindingAreaSiteByProtocolNoUsingPOST(
    ClientSiteAreaReq:TkApi.ClientSiteAreaReq,

  ) {
    return request.post<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/listProjectBindingAreaSiteByProtocolNo', ClientSiteAreaReq);
  }

  /**
  * 查询未对码院区
  * @param {Array<number>} areaSiteIds - 院区id列表
  * @param {string} code - 编号
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} name - 名称
  * @param {string} orderBy - 排序字段
  * @param {number} projectId - 项目id
  * @param {string} protocolNo - 临床编号
  * @param {number} size - 一页几条
  * @param {number} sponsorId - 申办方id
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} studyId - 试验id
  * @param {number} tenantId - 租户id
  */
  static listSiteAreaUnBindRefUsingGET(params?: {
                            areaSiteIds?:Array<number>,
                            code?:string,
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            name?:string,
                            orderBy?:string,
                            projectId?:number,
                            protocolNo?:string,
                            size?:number,
                            sponsorId?:number,
                            status?:number,
                            studyId?:number,
                            tenantId?:number,

                }) {
    return request.get<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/listSiteAreaUnBindRef',
      params);
  }

  /**
  * 分页查询
  * @param {Array<number>} areaSiteIds - 院区id列表
  * @param {string} code - 编号
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} name - 名称
  * @param {string} orderBy - 排序字段
  * @param {number} projectId - 项目id
  * @param {string} protocolNo - 临床编号
  * @param {number} size - 一页几条
  * @param {number} sponsorId - 申办方id
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} studyId - 试验id
  * @param {number} tenantId - 租户id
  */
  static pageUsingGET(params?: {
                            areaSiteIds?:Array<number>,
                            code?:string,
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            name?:string,
                            orderBy?:string,
                            projectId?:number,
                            protocolNo?:string,
                            size?:number,
                            sponsorId?:number,
                            status?:number,
                            studyId?:number,
                            tenantId?:number,

                }) {
    return request.get<TkApi.PageVoClientSiteAreaVo>('/client/v1/siteArea/page',
      params);
  }

  /**
  * 分页查询项目已绑定院区信息
  * @param {TkApi.ClientSiteAreaReq} ClientSiteAreaReq - clientSiteAreaReq
  */
  static pageProjectBindingAreaSiteUsingPOST(
    ClientSiteAreaReq:TkApi.ClientSiteAreaReq,

  ) {
    return request.post<TkApi.PageVoClientSiteAreaVo>('/client/v1/siteArea/pageProjectBindingAreaSite', ClientSiteAreaReq);
  }

  /**
  * 保存或修改
  * @param {TkApi.ClientSiteAreaDto} ClientSiteAreaDto - request
  */
  static saveUsingPOST(
    ClientSiteAreaDto:TkApi.ClientSiteAreaDto,

  ) {
    return request.post<TkApi.ClientSiteAreaVo>('/client/v1/siteArea/save', ClientSiteAreaDto);
  }

  /**
  * 申办方端查询院区
  * @param {Array<number>} areaSiteIds - 院区id列表
  * @param {string} code - 编号
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} name - 名称
  * @param {string} orderBy - 排序字段
  * @param {number} projectId - 项目id
  * @param {string} protocolNo - 临床编号
  * @param {number} size - 一页几条
  * @param {number} sponsorId - 申办方id
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} studyId - 试验id
  * @param {number} tenantId - 租户id
  */
  static sponsorSiteAreaListUsingGET(params?: {
                            areaSiteIds?:Array<number>,
                            code?:string,
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            name?:string,
                            orderBy?:string,
                            projectId?:number,
                            protocolNo?:string,
                            size?:number,
                            sponsorId?:number,
                            status?:number,
                            studyId?:number,
                            tenantId?:number,

                }) {
    return request.get<Array<TkApi.ClientSiteAreaVo>>('/client/v1/siteArea/sponsorSiteAreaList',
      params);
  }

  /**
  * 批量删除
  * @param {string} ids - 主键id
  */
  static deleteUsingDELETE(
    ids:string,

  ) {
    return request.delete<TkApi.ClientSiteAreaVo>(`/client/v1/siteArea/${ids}`);
  }

  /**
  * 根据id查询
  * @param {number} id - 主键id
  */
  static getUsingGET(
    id:number,

  ) {
    return request.get<TkApi.ClientSiteAreaVo>(`/client/v1/siteArea/${id}`);
  }
}
