import { LANGUAGE_EN, SELECT_LOCALE } from '@/constant/key';
import { defineStore } from 'pinia';
import { store } from '@/store';
import localStore from 'store2';
import { localeSetting } from '@/settings/localeSetting';

const lsLocaleSetting = localStore(SELECT_LOCALE) || localeSetting;

interface LocaleState {
  localInfo: any;
  pathTitleMap: any;
}

export const useLocaleStore = defineStore({
  id: 'app-locale',
  state: (): LocaleState => ({
    localInfo: lsLocaleSetting,
    pathTitleMap: {},
  }),
  getters: {
    getShowPicker(): boolean {
      return !!this.localInfo?.showPicker;
    },
    getLocale() {
      return this.localInfo?.locale ?? LANGUAGE_EN;
    },
    getPathTitle: (state) => (path) => state.pathTitleMap[path],
  },
  actions: {
    /**
     * Set up multilingual information and cache
     * @param info multilingual info
     */
    setLocaleInfo(info) {
      this.localInfo = { ...this.localInfo, ...info };
      localStore(SELECT_LOCALE, this.localInfo);
    },
    /**
     * Initialize multilingual information and load the existing configuration from the local cache
     */
    initLocale() {
      this.setLocaleInfo({
        ...localeSetting,
        ...this.localInfo,
      });
    },
    setPathTitle(path, title) {
      this.pathTitleMap[path] = title;
    },
  },
});

// Need to be used outside the setup
export function useLocaleStoreWithOut() {
  return useLocaleStore(store);
}
