import { SysLoginAPI } from '@/api/SysLoginController';
import { useMessage } from '@root/hooks';
import { useEventListener } from '@vueuse/core';
import { ref } from 'vue';
import type {
  ObjectDirective,
  App,
} from 'vue';
import { useUserStore } from '@/store/modules/user';
import { Input } from 'ant-design-vue';

const signButtonAuth = (el: Element, binding: { value }) => {
  const userStore = useUserStore();
  const { createConfirmModal } = useMessage();
  // 权限列表
  // @ts-ignore
  const authList = userStore.getAuth;
  const { value } = binding;
  if (!value) return;
  // 是否需要电子签名
  const { needSignature, action } = authList.find((_) => _.action === value) ?? {};
  if (needSignature) {
    const cleanup = useEventListener(el, 'click', (evt) => {
      // 阻止点击
      evt.stopPropagation();
      const signNature = ref('');
      createConfirmModal({
        title: '电子签名',
        content: <div>
          {/* @ts-ignore */}
          <Input.Password autocomplete="new-password" onChange={(event) => signNature.value = event.target.value as string} />
        </div>,
        onOk: async () => {
          // 验证电子签名
          await SysLoginAPI.validateSignatureUsingPOST({
            signaturePassword: signNature.value,
            operateType: action,
          });
          // 删除电子签名事件
          cleanup();
          // 验证完毕 提交
          setTimeout(() => {
            el.dispatchEvent(new Event('click'));
          }, 500);
        },
        onCancel: () => signNature.value = '',
      });
    }, { capture: true });
  }
};

const mounted = (el, binding) => {
  signButtonAuth(el, binding);
};

// 按钮电子签名指令
export const vSign: ObjectDirective<any, string> = {
  mounted,
};

export function setupSignAuthDirective(app: App) {
  app.directive('sign', vSign);
}
