import type {
  App, Directive, DirectiveBinding, VNode,
} from 'vue';
import { useUserStore } from '@/store/modules/user';

const hasButtonAuth = (el: Element, binding: any, vnode: any) => {
  const userStore = useUserStore();
  // 用户拥有的权限
  const userAction = userStore.getUserAuthActionArray;
  const { value } = binding;
  if (!value) return;
  // 当前绑定的所有权限
  const auths = value instanceof Array ? value : [value];
  // 如果用户拥有当前绑定的任意权限
  for (const auth of auths) {
    if (userAction.includes(auth)) return;
  }
  // 没有权限进行移除操作
  el.remove();
};

const mounted = (el: Element, binding: DirectiveBinding<any>, vnode: VNode) => {
  hasButtonAuth(el, binding, vnode);
};

// 按钮权限指令
const hasAuth: Directive = {
  mounted,
};

export function setupHasAuthDirective(app: App) {
  app.directive('has', hasAuth);
}
