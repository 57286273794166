/**
 *  created by lllwx
 *  Date: 2021/6/2
 *  Time: 1:51 下午
 *  Version: 1.0
 *  For:
 */
import { APP_VERSION, PLATFORM } from '@/constant';
import { SITE, TENANT, TOKEN } from '@/constant/key';
import { useUserStore } from '@/store/modules/user';
import { compareVersion, removeGetRequestInvalidParam } from '@root/utils';
import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Canceler,
} from 'axios';
import { message, Modal } from 'ant-design-vue';
import qs from 'qs';
import { CONTENT_TYPE_ENUM, HTTP_STATUS } from '@root/tools/enum';
import store from 'store2';

const successCode = [HTTP_STATUS.ACCEPTED, HTTP_STATUS.CREATED, HTTP_STATUS.SUCCESS, HTTP_STATUS.CUSTOM_SUCCESS, HTTP_STATUS.MANAGE_SUCCESS];

const serverErrorCode = [HTTP_STATUS.SERVER_ERROR, HTTP_STATUS.GATEWAY_TIMEOUT, HTTP_STATUS.BAD_GATEWAY, HTTP_STATUS.SERVICE_UNAVAILABLE];

type CancelRequest = {
  mark: string,
  cancel: Canceler
  routeChangeCancel: boolean
}

// 当前缓存的pending状态的请求
export const pendingRequest: CancelRequest[] = [];

// 不取消的请求
export const notRouteChangeCancel: string[] = [];

// 接口错误提示集合 防止相同错误信息重复展示
const messageSet = new Set();

export class Request {
  // 唯一实例
  private static instance: undefined | Request;

  // 请求对象
  private service: AxiosInstance;

  // 单例获取
  public static getInstance(): Request {
    this.instance || (this.instance = new Request());
    return this.instance;
  }

  constructor() {
    this.service = axios.create({
      baseURL: import.meta.env.MODE === 'development' ? '' : import.meta.env.VITE_BASE_URL,
      timeout: Number.parseInt(import.meta.env.VITE_TIME_OUT, 10),
      routeChangeCancel: true,
    });
    this.requestInterceptors();
    this.responseInterceptors();
  }

  // request 添加 mark
  static requestMark(config: AxiosRequestConfig) {
    // 请求标志
    const requestMark = `${config.url}/${config.method}`;
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    config.requestMark = requestMark;
    // 加入到pending请求队列中
    pendingRequest.push({
      mark: requestMark,
      cancel: source.cancel,
      // 是否在切换路由取消请求 默认true
      routeChangeCancel: !!config.routeChangeCancel,
    });
  }

  // 请求拦截 配置全局参数
  static requestDataConfig(config: AxiosRequestConfig) {
    config.headers!.platformCode = PLATFORM;
    // token配置
    config.headers!['X-Access-Token'] = store(TOKEN) ?? '';
    // 院区配置
    config.headers!.siteAreaId = store(SITE) ?? '';
    // 租户id
    config.headers!.tenantId = (store(TENANT) as TkApi.SysTenantVo)?.id ?? '';
    // 移除无效的参数
    removeGetRequestInvalidParam(config.params);
  }

  // 请求 mock 配置
  static requestMockConfig(config: AxiosRequestConfig) {
    // 开发模式才可以使用mock
    if (import.meta.env.MODE === 'development') {
      // 所有接口网关 列表
      const proxyList = ['irt-client/', 'irt-meta/', 'irt-system/'];
      if (config.useMock) {
        config.url = `/mock-api${config.url}`;
        // url移除网关
        proxyList.forEach((proxy) => config.url = config.url?.replace(proxy, ''));
      }
    }
  }

  // 请求拦截
  protected requestInterceptors(): void {
    this.service.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        Request.requestDataConfig(config);

        Request.requestMark(config);

        Request.requestMockConfig(config);

        // 如果是导出操作 添加responseType
        if (config.url?.toLowerCase().includes('export')) {
          config.responseType = 'blob';
        }

        if (config.method === 'get') {
          // 默认展示全局message
          config.isMessage = config.params?.isMessage ?? true;
        } else {
          config.isMessage = config.data?.isMessage ?? true;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );
  }

  // 响应拦截
  protected responseInterceptors(): void {
    this.service.interceptors.response.use(
      async (response: AxiosResponse) => {
        Request.responseMark(response);
        try {
          await Request.responseBlobFileError(response);
        } catch {
          return Promise.reject();
        }

        const data = Request.responseMockApi(response);
        if (data) {
          return data;
        }

        // 200
        if (successCode.indexOf(response.status) !== -1) {
          if (response.headers['content-disposition']) {
            return response;
          }

          // 更新确认
          // @ts-ignore
          if (process.env.NODE_ENV !== 'development') {
            Request.checkAppRefresh(response);
          }

          // 接口返回200
          if (successCode.indexOf(response.data.code) !== -1) {
            return response.data;
          }

          // 如果需要展示全局message提示 相同的错误提示不重复展示
          const errorMessage = response.data.message;
          if (response.config.isMessage && !messageSet.has(errorMessage)) {
            Request.errorMessageShow(errorMessage);
          }
          // 登录失效
          if (response.data.code === HTTP_STATUS.UNAUTHORIZED) {
            store.clearAll();
            useUserStore().clear();
            window.location.replace('/login');
            return Promise.reject();
          }
          return Promise.reject(response.data);
        }
        if (serverErrorCode.indexOf(response.status) !== -1) {
          // 服务器错误
          message.error('网络繁忙');
        }
      },
      (error: AxiosError) => {
        if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
          const errorMessage = error.response?.data.message ?? '';
          // 如果需要展示全局message提示 相同的错误提示不重复展示
          if (error.config.isMessage && !messageSet.has(errorMessage)) {
            Request.errorMessageShow(errorMessage);
          }
          store.clearAll();
          useUserStore().clear();
          window.location.replace('/login');
        }
        return Promise.reject(error);
      },
    );
  }

  private static refreshModal: any = null;

  // 是否有新页面需要刷新
  static checkAppRefresh(response: AxiosResponse) {
    // eslint-disable-next-line no-console
    console.log('response:', response);
    // eslint-disable-next-line no-console
    console.log('headers: ', response.headers);
    const appVersion = response.headers['app-version'];
    // 当前版本号 小于 响应头的版本号
    if (appVersion && compareVersion(appVersion, APP_VERSION) > 0) {
      // 避免重复弹窗
      if (!Request.refreshModal) {
        Request.refreshModal = Modal.confirm({
          title: '当前应用已发布新版本需要点击刷新',
          onOk: () => {
            Request.refreshModal.destroy();
            Request.refreshModal = null;
          },
          keyboard: false,
          maskClosable: false,
          okText: '确定',
          okCancel: false,
          afterClose: () => {
            window.location.reload();
          },
        });
      }
    }
    return compareVersion(appVersion, APP_VERSION) > 0;
  }

  // 错误信息展示
  static errorMessageShow(errorMessage) {
    messageSet.add(errorMessage);
    message.error({
      content: errorMessage,
      duration: 2,
      onClose: () => {
        messageSet.delete(errorMessage);
      },
    });
  }

  // mock api 处理
  static responseMockApi(response: AxiosResponse) {
    // 开发模式才可以使用mock
    if (import.meta.env.MODE === 'development') {
      const { config } = response;
      if (config.useMock) {
        if (response.data.result) return response.data;
        return {
          result: response.data,
        };
      }
    }
  }

  // 请求响应移除mark
  static responseMark(response: AxiosResponse) {
    // 从pending请求队列删除
    const markIndex = pendingRequest.findIndex((item) => item.mark === response.config.requestMark);
    markIndex !== -1 && pendingRequest.splice(markIndex, 1);
  }

  // 请求响应 blob文件流 报错
  static responseBlobFileError(response: AxiosResponse) {
    // 导出字节流文件 报错
    if (response.data.type === 'application/json' && response.config.url?.includes('export')) {
      // blob转化为json数据
      const reader = new FileReader();
      reader.readAsText(response.data, 'utf-8');
      reader.onload = () => {
        const res = JSON.parse(reader.result as string);
        message.error(res.message ?? '');
      };
      return Promise.reject();
    }
  }

  /**
   * @description get请求
   * @param {string} url
   * @param params
   * @param {AxiosRequestHeaders} header
   * @returns {Promise<AxiosResponse<any>>}
   */
  public async get<T = any>(url: string, params: any = {}, header: AxiosRequestHeaders = {}): Promise<{ code: HTTP_STATUS, result: T, message: string }> {
    return await this.service.get(`${url}?${qs.stringify(params)}`, {
      headers: {
        ...header,
      },
    });
  }

  /**
   * @description delete 请求
   * @param {string} url
   * @param params
   * @returns {Promise<any>}
   */
  public async delete<T = any>(url: string, params: any = {}): Promise<{ code: HTTP_STATUS, result: T, message: string }> {
    return await this.service.delete(url, {
      params,
    });
  }

  /**
   * @description post 请求
   * @param {string} url
   * @param data
   * @param {AxiosRequestHeaders} header
   * @returns {Promise<AxiosResponse<any>>}
   */
  public async post<T = any>(url: string, data: any = {}, header: AxiosRequestHeaders = {}): Promise<{ code: HTTP_STATUS, result: T, message: string }> {
    if (header?.['content-type'] === CONTENT_TYPE_ENUM.FORM_URLENCODED) {
      data = qs.stringify(data);
    }
    // 如果是表单文件提交
    if (header?.['content-type'] === CONTENT_TYPE_ENUM.FORM_DATA) {
      data = data?.file;
    }
    return await this.service.post(url, data, {
      headers: {
        ...header,
      },
    });
  }

  /**
   * @description put 请求
   * @param {string} url
   * @param data
   * @param config
   * @returns {Promise<AxiosResponse<any>>}
   */
  public async put<T = any>(url: string, data: any = {}, config: Record<string, string> = {}): Promise<{ code: HTTP_STATUS, result: T, message: string }> {
    return await this.service.put(url, data);
  }
}
