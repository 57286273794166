import { MENU_TABS } from '@root/tools/constant';
import { defineStore } from 'pinia';
import store from 'store2';

type Auth = {
  action: string,
}

interface UserState {
  userInfo: Record<string, string>;
  loginInfo: Record<string, string>;
  auth?: Auth[],
  menu?: TkApi.MenuVo []
  tabs?: TkApi.MenuVo []
  disableCache: string[]
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    userInfo: {},
    loginInfo: {},
    menu: [],
    auth: [],
    tabs: [],
    disableCache: [],
  }),
  getters: {
    getUserAuthActionArray(): string[] {
      return this.auth?.map((_) => _.action) ?? [];
    },
    getTabs(): TkApi.MenuVo [] {
      return this.tabs ?? [];
    },
  },
  actions: {
    setUserInfo(info) {
      this.userInfo = info;
    },
    setMenu(menu) {
      this.menu = menu ?? [];
    },
    setTabs(tabs: TkApi.MenuVo | TkApi.MenuVo[]) {
      if (tabs instanceof Array) {
        this.tabs = tabs;
        return;
      }
      if (!this.tabs?.find((_) => _.name === tabs.name)) {
        this.tabs?.push(tabs);
      } else {
        // 如果是已经存在的tab 就更新tab数据
        const index = this.tabs?.findIndex((_) => _.name === tabs.name);
        this.tabs[index] = tabs;
      }
      store(MENU_TABS, this.tabs);
    },
    deleteTabs(tabName: string) {
      const index = this.tabs?.findIndex((_) => _.name === tabName);
      if (tabName && index !== -1) {
        this.tabs?.splice(index!, 1);
        store(MENU_TABS, this.tabs);
        return index;
      }
    },
    setAuth(auth) {
      this.auth = auth ?? [];
    },
    // 返回用户是否有当前权限
    getUserHasAuth(auth: string) {
      const userAuth = this.auth?.map((_) => _.action) ?? [];
      return userAuth.includes(auth);
    },
    setDisableCache(disabledCache) {
      this.disableCache = disabledCache;
      if (disabledCache.length) {
        // 两秒之后把exclude的缓存 清空
        const cacheTime = 2;
        setTimeout(() => {
          this.disableCache = [];
        }, cacheTime * 1000);
      }
    },
    clear() {
      this.menu = [];
      this.auth = [];
      this.tabs = [];
      this.userInfo = {};
    },
  },
});
