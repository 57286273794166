import { ClientSiteAreaAPI } from '@/api/ClientSiteAreaController';
import { SysDictAPI } from '@/api/SysDictController';
import {
  DICT, TENANT, TOKEN, USER_INFO,
} from '@/constant/key';
import { useSettingStore } from '@/store/modules/setting';
import store from 'store2';

export const getUserSystemInfo = async () => {
  const settingStore = useSettingStore();
  if (store(TOKEN)) {
    // 字典数据
    const { result = [] } = await SysDictAPI.listAllDictMapUsingGET();
    store(DICT, result);
    // 院区数据
    const res = await ClientSiteAreaAPI.listByUsernameUsingGET({
      tenantId: store(TENANT).id,
      username: store(USER_INFO)?.username,
    });
    settingStore.setSiteList(res.result ?? []);
  }
};
