import { setupI18n } from '@/locales/setupI18n';
import { setupRouter } from '@/router';
import { tableConfig } from '@root/hooks/useTable';
import { queryTableConfig } from '@root/hooks/useTableQuery';
import { getApp } from '@root/utils/global';
import { registerCharts } from '@/utils/register-charts';
import { pageSetting } from '@root/tools/constant';
import { h } from 'vue';
import ECharts from 'vue-echarts'; // v-chars
import App from './App.vue';

import 'nprogress/nprogress.css'; // nprogress
import '@/styles/tailwind.css';
import '@/styles/index.less';
import '@root/tools/theme/theme-client.less';

// 注册组件
import { registerAntd } from '@/utils/register-antd';
import { registerThirdComp } from '@/utils/register-third-comp';
import { registerGlobalComponent } from '@/components';

// 注册全局指令
import { setupGlobDirectives } from '@/directives';

import 'virtual:svg-icons-register';
import { setupStore } from '@/store';

const app = getApp(App);

// 配置分页参数
pageSetting.pageNo = 'current';
pageSetting.pageSize = 'size';

// 配置表格全局props
tableConfig.headerCell = ({ title }) => h('div', { class: 'font-bold' }, title);
queryTableConfig.headerCell = ({ title }) => h('div', { class: 'font-bold' }, title);

async function bootstrap() {
  // 多语言配置,异步情况:语言文件可以从服务器端获得
  await setupI18n(app);

  // 注册需要使用的antd 组件
  registerAntd(app);

  // 注册第三方组件
  registerThirdComp(app);

  // 注册全局组件
  registerGlobalComponent(app);

  // 注册pinia
  setupStore(app);

  // echarts引入
  registerCharts();

  // 配置路由
  setupRouter(app);

  // 全局指令
  setupGlobDirectives(app);

  app.component('VChart', ECharts);

  // 挂载应用
  app.mount('#app');
}

bootstrap();
