import { SysLoginAPI } from '@/api/SysLoginController';
import { useUserStore } from '@/store/modules/user';
import { convertToFlat } from '@root/utils/structure-convert';
import { cloneDeep } from 'lodash-es';
import { router, WHITE_NAME_LIST } from './index';

const modules = import.meta.glob('../views/**/*.vue');

export async function generateAsyncRoute() {
  const userStore = useUserStore();
  if (userStore.menu?.length) return;
  const { result } = await SysLoginAPI.getRoutersUsingGET();
  const { menu, auth } = result;
  userStore.setAuth(auth);
  userStore.setMenu(menu);
  resetRoute();
  addAsyncRoute(convertToFlat(cloneDeep(menu)) ?? []);
}

const isRouteView = (component) => component === 'Layout';

function addAsyncRoute(menus: TkApi.MenuVo[], parent?) {
  const indexRoute = router.getRoutes().find((_) => _.name === 'Index')!;
  if (menus.length) {
    menus.forEach((_) => {
      const {
        name, redirect, meta, component,
      } = _;
      const route = {
        path: name,
        name,
        redirect,
        meta: {
          ...meta,
          isComponent: !isRouteView(component),
        },
        children: [],
        component: isRouteView(component) ? modules['../views/default.vue'] : modules[`../views/${component}.vue`],
      };
      parent ? parent.children.push(route) : indexRoute.children.push(route);
      if (_.children && _.children.length) {
        addAsyncRoute(_.children, route);
      }
    });
  }
  router.addRoute(indexRoute);
}

// 重新设置 路由
function resetRoute() {
  router.getRoutes().forEach((_) => {
    if (WHITE_NAME_LIST.includes(_.name as string) || _.name === 'Index') return;
    router.removeRoute(_.name as string);
  });
}
