/**
 *  created by wenxiao.li
 *  Date: 2021/6/9
 *  Time: 19:35
 *  Version: 1.0
 *  For: 消息,通知
 */
import { message as Message, Modal } from 'ant-design-vue';
import {
  InfoCircleFilled, CheckCircleFilled, WarningOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { ModalFuncProps } from 'ant-design-vue/lib/modal/Modal';
import { useI18n } from './useI18n';

export function useMessage() {
  const { t } = useI18n('global');

  const baseOptions = {
    centered: true,
    maskClosable: false,
    closable: true,
    width: 500,
    okText: t('confirmText'),
    cancelText: t('cancelText'),
  };

  function renderContent({ content }: ModalFuncProps) {
    return <div innerHTML={`<div>${content}</div>`}></div>;
  }

  function getIcon(iconType: string) {
    if (iconType === 'warning') {
      return <WarningOutlined class="modal-icon-warning" />;
    } if (iconType === 'success') {
      return <CheckCircleFilled class="modal-icon-success" />;
    } if (iconType === 'confirm') {
      return <ExclamationCircleOutlined class="modal-icon-confirm" />;
    } if (iconType === 'info') {
      return <InfoCircleFilled class="modal-icon-info" />;
    }
  }

  function createModalOptions(options: ModalFuncProps, icon: string) {
    return {
      ...baseOptions,
      ...options,
      content: typeof options.content === 'string' ? renderContent(options) : options.content,
      icon: getIcon(icon),
    };
  }

  function createInfoModal(options: ModalFuncProps) {
    return Modal.info(createModalOptions({
      ...options,
      class: 'info-modal',
    }, 'info'));
  }
  function createConfirmModal(options: ModalFuncProps) {
    return Modal.confirm(createModalOptions({
      ...options,
      class: 'confirm-modal',
    }, 'confirm'));
  }
  function createWarningModal(options: ModalFuncProps) {
    return Modal.warning(createModalOptions({
      ...options,
      class: 'waring-modal',
    }, 'warning'));
  }
  function createModal(options: ModalFuncProps) {
    return Modal.info(createModalOptions({
      ...options,
      class: 'info-modal',
    }, ''));
  }

  return {
    createMessage: Message,
    createInfoModal,
    createWarningModal,
    createConfirmModal,
    createModal,
  };
}
