/**
 *  created by lllwx
 *  @date: 2022/7/22
 *  @time: 16:40
 *  @version: 1.0
 *  @desc: 通用工具方法
 */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { App } from 'vue';

dayjs.extend(duration);

/**
 * @desc 对日期进行标准格式化
 * @author lllwx
 * @date 2021/11/1 1:46 下午
 * @param {string | Date} date 日期对象 或 时间字符串
 * @param {string} format 格式化标准
 * @return {string}
 */
export function formatDate(date: string | Date | number, format?: string): string {
  const baseFormat = 'YYYY-MM-DD HH:mm:ss';
  if (!date) return '';
  return dayjs(date).format(format || baseFormat);
}

/**
 * @desc 对duration进行格式化
 * @author lllwx
 * @date 2022/8/4 13:52
 * @param {number} second 多少秒
 * @return {string}
 */
export function formatSecond(second: number): string {
  const baseFormat = 'HH:mm:ss';
  if (!second) return '';
  const hours = Math.floor(second / 60 / 60);
  const minutes = Math.floor((second - (hours * 60 * 60)) / 60);
  const seconds = second - (hours * 60 * 60) - (minutes * 60);
  return dayjs.duration({
    hours,
    minutes,
    seconds,
  }).format(baseFormat);
}

/**
 * @desc 获取文件的扩展名
 * @author lllwx
 * @date 2022/8/3 16:35
 * @param fileName
 * @return string
 */
export function getFileExtension(fileName?: string): string {
  if (fileName?.includes('.')) {
    return fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  }
  return '';
}

/**
 * @desc 获取guid
 * @author lllwx
 * @date 2021/6/1 11:13 上午
 * @return { string }
 */
export function getGuid(): string {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return S4() + S4() + S4() + S4() + S4() + S4() + S4();
}

// 业务常量定义类型
type BusinessConstantType = {
    label: string,
    value: string | number,
    name?: string
}

/**
 * 业务常量的使用方式扩展
 * @param {Array} source
 * @returns {Function} fn
 fn() ===> source
 fn(value) ===> label
 fn(name) ===> value
 */
export default function createEnum(source: BusinessConstantType[]) {
  const sourceMap = new Map();
  source.forEach((item) => {
    sourceMap.set(item.name, item.value);
    sourceMap.set(item.value, item.label);
  });

  return function (key?: string | number) {
    if (key) {
      return sourceMap.get(key);
    }
    return source;
  };
}

/**
 * @desc 所有图片转为webp格式
 * @author lllwx
 * @date 2022/7/22 16:42
 * @param file
 * @return Promise
 */
export const image2Webp = (file: File): Promise<File> => {
  const image = new Image();
  image.src = URL.createObjectURL(file);
  image.crossOrigin = 'Anonymous';
  return new Promise(((resolve) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      canvas.getContext('2d')?.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          const myImage = new File([blob], 'my.webp', { type: blob.type });
          resolve(myImage);
        }
      }, 'image/webp');
    };
  }));
};

/**
 * @desc 下载文件流 文件
 * @author lllwx
 * @date 2022/12/26 19:48
 * @param response
 * @return
 */
export const downloadBlobFile = (response) => {
  // content-disposition取fileName
  const disposition = response.headers['content-disposition'];
  let fileName = '';
  if (disposition) {
    fileName = disposition.slice(disposition.indexOf('=') + 1);
  }
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.append(link);
  link.href = URL.createObjectURL(new Blob([response.data]));
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
};

/**
 * @desc 根据url下载文件
 * @author gs
 * @date 2023/01/16 15:17
 * @param url
 * @param target
 * @return
 */
export const downloadUrlFile = (url:string, target?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';
  link.target = target ?? '_self';
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};

export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any;
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component as any);
    if (alias) {
      app.config.globalProperties[alias] = component;
    }
  };
  return component as T & Plugin;
};

/**
 * @desc 移除get请求中带_的参数
 * @author lllwx
 * @date 2023/8/8 15:12
 * @param params
 */
export const removeGetRequestInvalidParam = (params: Record<string, any>) => {
  const keyArray: string[] = [];
  // 循环获取带_的参数名称
  Object.keys((params ?? {})).forEach((key) => {
    key.includes('_') && keyArray.push(key);
  });
  // 删除所有带_的参数
  keyArray.forEach((key) => delete params[key]);
};

/**
 * @desc 版本号比较
 * @author lllwx
 * @date 2023/11/24 13:42
 * @param version1
 * @param version2
 * @return version1 > version2 return 1
 */
export const compareVersion = (version1: string, version2: string) => {
  const v1 = version1.split('.');
  const v2 = version2.split('.');
  const len = Math.max(v1.length, v2.length);
  while (v1.length < len) {
    v1.push('0');
  }
  while (v2.length < len) {
    v2.push('0');
  }
  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i], 10);
    const num2 = parseInt(v2[i], 10);
    if (num1 > num2) {
      return 1;
    } if (num1 < num2) {
      return -1;
    }
  }
  return 0;
};

/**
 * @desc 树扁平化
 * @author lllwx
 * @date 2023/12/12 15:04
 * @param tree
 * @return  array
 */
export const treeToArray = (tree) => tree.reduce((res, item) => {
  const { children, ...i } = item;
  return res.concat(i, children && children.length ? treeToArray(children) : []);
}, []);

// 设置下拉选项的挂载容器
export const getPopContainer = (triggerNode) => triggerNode.parentNode;

// 格式化打印kitNo拆零数据(根据拆零系数重复拆零kitNo)
export const formatMorePrint = (list:any[], num:number) => list.reduce((pre, cur) => pre.concat(new Array(num).fill(cur)), []);
