import { RouteRecordRaw } from 'vue-router';

export const LoginRoute: RouteRecordRaw = {
  path: '/login',
  name: 'Login',
  component: () => import(/* webpackChunkName: "login" */'@/views/login/login.vue'),
  meta: {
    title: 'Login',
  },
};

export const DashboardRoute: RouteRecordRaw = {
  path: '/dashboard',
  name: 'dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */'@/views/dashboard/dashboard.vue'),
  meta: {
    title: '数据看板',
  },
};

export const UserCenterRoute: RouteRecordRaw = {
  path: '/user-center',
  name: 'user-center',
  component: () => import(/* webpackChunkName: "dashboard" */'@/views/user/user-center/index.vue'),
  meta: {
    title: '个人中心',
  },
};

export const basicRoutes: RouteRecordRaw[] = [LoginRoute, DashboardRoute, UserCenterRoute];
