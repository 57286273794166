import { Request } from '@/utils/request';

const request = Request.getInstance();

/**
 * Sys Login Controller
 */
export class SysLoginAPI {
  /**
  * 用户激活
  * @param {TkApi.ActiveUserDto} ActiveUserDto - activeUserDto
  */
  static activeUserUsingPOST(
    ActiveUserDto:TkApi.ActiveUserDto,

  ) {
    return request.post<boolean>('/meta/v1/sys/activeUser', ActiveUserDto);
  }

  /**
  * 激活用户信息
  * @param {string} token - token
  */
  static activeUserInfoUsingGET(params?: {
                            token:string,

                }) {
    return request.get<TkApi.SysUserVo>('/meta/v1/sys/activeUserInfo',
      params);
  }

  /**
  * App登录
  * @param {TkApi.LoginDto} LoginDto - wxLoginDto
  */
  static appLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.AppLoginVo>('/meta/v1/sys/appLogin', LoginDto);
  }

  /**
  * 微信小程序openId校验
  * @param {TkApi.AuthOpenidDto} AuthOpenidDto - authOpenidDto
  */
  static authOpenidUsingPOST(
    AuthOpenidDto:TkApi.AuthOpenidDto,

  ) {
    return request.post<TkApi.WxLoginVo>('/meta/v1/sys/authOpenid', AuthOpenidDto);
  }

  /**
  * 微信小程序凭证校验
  * @param {TkApi.AuthWxCodeDto} AuthWxCodeDto - authWxCodeDto
  */
  static authWxCodeUsingPOST(
    AuthWxCodeDto:TkApi.AuthWxCodeDto,

  ) {
    return request.post<TkApi.AuthWxCodeVo>('/meta/v1/sys/authWxCode', AuthWxCodeDto);
  }

  /**
  * token校验
  */
  static checkTokenUsingGET(

  ) {
    return request.get<string>('/meta/v1/sys/checkToken');
  }

  /**
  * 校验用户名和邮箱是否一致
  * @param {TkApi.CheckUsernameAndEmailDto} CheckUsernameAndEmailDto - dto
  */
  static checkUsernameAndEmailUsingPOST(
    CheckUsernameAndEmailDto:TkApi.CheckUsernameAndEmailDto,

  ) {
    return request.post<any>('/meta/v1/sys/checkUsernameAndEmail', CheckUsernameAndEmailDto);
  }

  /**
  * 智能药柜用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static equipLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.LoginVo>('/meta/v1/sys/equip/login', LoginDto);
  }

  /**
  * 获取帮助系统的token
  */
  static getHelperAppTokenUsingGET(

  ) {
    return request.get<string>('/meta/v1/sys/getHelperAppToken');
  }

  /**
  * 获取登录二维码
  */
  static getLoginQrcodeUsingGET(

  ) {
    return request.get<TkApi.LoginScanVo>('/meta/v1/sys/getLoginQrcode');
  }

  /**
  * 获取菜单路由
  */
  static getRoutersUsingGET(

  ) {
    return request.get<TkApi.RouterVo>('/meta/v1/sys/getRouters');
  }

  /**
  * 获取用户信息
  */
  static getUserInfoUsingGET(

  ) {
    return request.get<TkApi.UserInfoVo>('/meta/v1/sys/getUserInfo');
  }

  /**
  * 获取用户信息
  * @param {number} userId - userId
  */
  static getUserInfoUsingGET(
    userId:number,

  ) {
    return request.get<TkApi.UserInfoVo>(`/meta/v1/sys/getUserInfo/${userId}`);
  }

  /**
  * 获取微信accessToken
  * @param {string} appId - appId
  */
  static getWxAccessTokenByAppIdUsingGET(params?: {
                            appId:string,

                }) {
    return request.get<TkApi.AuthWxAccessTokenVo>('/meta/v1/sys/getWxAccessTokenByAppId',
      params);
  }

  /**
  * 主数据用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static loginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.LoginVo>('/meta/v1/sys/login', LoginDto);
  }

  /**
  * 退出登录
  */
  static logoutUsingDELETE(

  ) {
    return request.delete<any>('/meta/v1/sys/logout');
  }

  /**
  * 扫码登录
  * @param {TkApi.LoginScanDto} LoginScanDto - dto
  */
  static qrcodeLoginUsingPOST(
    LoginScanDto:TkApi.LoginScanDto,

  ) {
    return request.post<any>('/meta/v1/sys/qrcodeLogin', LoginScanDto);
  }

  /**
  * 根据邮箱重置密码
  * @param {TkApi.ResetPasswordDto} ResetPasswordDto - resetPasswordDto
  */
  static resetPasswordUsingPOST(
    ResetPasswordDto:TkApi.ResetPasswordDto,

  ) {
    return request.post<any>('/meta/v1/sys/resetPassword', ResetPasswordDto);
  }

  /**
  * 轮询二维码
  * @param {TkApi.LoginScanDto} LoginScanDto - dto
  */
  static scanLoginQrcodeUsingPOST(
    LoginScanDto:TkApi.LoginScanDto,

  ) {
    return request.post<TkApi.TenantLoginVo>('/meta/v1/sys/scanLoginQrcode', LoginScanDto);
  }

  /**
  * 发送邮箱验证码
  * @param {string} email - email
  */
  static sendEmailVerCodeUsingGET(params?: {
                            email:string,

                }) {
    return request.get<any>('/meta/v1/sys/sendEmailVerCode',
      params);
  }

  /**
  * 发送微信小程序订阅消息
  * @param {TkApi.WXSubscribeSendDto} WXSubscribeSendDto - messageDto
  */
  static sendWxSubscribeMessageUsingPOST(
    WXSubscribeSendDto:TkApi.WXSubscribeSendDto,

  ) {
    return request.post<TkApi.WxSubscribeSendResultVo>('/meta/v1/sys/sendWxSubscribeMessage', WXSubscribeSendDto);
  }

  /**
  * 申办方用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static sponsorLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.SponsorLoginVo>('/meta/v1/sys/sponsorLogin', LoginDto);
  }

  /**
  * 租户用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static tenantLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.TenantLoginVo>('/meta/v1/sys/tenantLogin', LoginDto);
  }

  /**
  * token校验1
  */
  static test1UsingGET(

  ) {
    return request.get<string>('/meta/v1/sys/test');
  }

  /**
  * 三方对接用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static thirdLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.LoginVo>('/meta/v1/sys/third/login', LoginDto);
  }

  /**
  * 修改密码
  * @param {TkApi.UpdatePasswordDto} UpdatePasswordDto - updatePasswordDto
  */
  static updatePasswordUsingPOST(
    UpdatePasswordDto:TkApi.UpdatePasswordDto,

  ) {
    return request.post<any>('/meta/v1/sys/updatePassword', UpdatePasswordDto);
  }

  /**
  * 发送重置密码邮件
  * @param {TkApi.UpdatePasswordDto} UpdatePasswordDto - updatePasswordDto
  */
  static updatePasswordEmailUsingPOST(
    UpdatePasswordDto:TkApi.UpdatePasswordDto,

  ) {
    return request.post<any>('/meta/v1/sys/updatePasswordEmail', UpdatePasswordDto);
  }

  /**
  * 修改电子签名
  * @param {TkApi.UpdateSignatureDto} UpdateSignatureDto - dto
  */
  static updateSignatureUsingPOST(
    UpdateSignatureDto:TkApi.UpdateSignatureDto,

  ) {
    return request.post<boolean>('/meta/v1/sys/updateSignature', UpdateSignatureDto);
  }

  /**
  * 电子签名验证
  * @param {TkApi.ValidateSignatureDto} ValidateSignatureDto - dto
  */
  static validateSignatureUsingPOST(
    ValidateSignatureDto:TkApi.ValidateSignatureDto,

  ) {
    return request.post<boolean>('/meta/v1/sys/validateSignature', ValidateSignatureDto);
  }

  /**
  * 供应商用户登录
  * @param {TkApi.LoginDto} LoginDto - loginReq
  */
  static vendorLoginUsingPOST(
    LoginDto:TkApi.LoginDto,

  ) {
    return request.post<TkApi.VendorLoginVo>('/meta/v1/sys/vendorLogin', LoginDto);
  }

  /**
  * 微信小程序登录
  * @param {TkApi.WxLoginDto} WxLoginDto - wxLoginDto
  */
  static wxLoginUsingPOST(
    WxLoginDto:TkApi.WxLoginDto,

  ) {
    return request.post<TkApi.WxLoginVo>('/meta/v1/sys/wxLogin', WxLoginDto);
  }
}
