import { Request } from '@/utils/request';

const request = Request.getInstance();

/**
 * Sys Dict Controller
 */
export class SysDictAPI {
  /**
  * 根据dictCode查询字典key
  * @param {string} dictCode - dictCode
  */
  static getDictByDictCodeUsingGET(params?: {
                            dictCode:string,

                }) {
    return request.get<TkApi.SysDict>('/meta/v1/sys/dict/getDictByDictCode',
      params);
  }

  /**
  * itemSave
  * @param {TkApi.SysDictItemDto} SysDictItemDto - request
  */
  static itemSaveUsingPOST(
    SysDictItemDto:TkApi.SysDictItemDto,

  ) {
    return request.post<TkApi.SysDictItemVo>('/meta/v1/sys/dict/item', SysDictItemDto);
  }

  /**
  * 客户端恢复字典值
  * @param {TkApi.SysDictItemExtDto} SysDictItemExtDto - sysDictItemExtDto
  */
  static itemExtRecoverForClientUsingPOST(
    SysDictItemExtDto:TkApi.SysDictItemExtDto,

  ) {
    return request.post<boolean>('/meta/v1/sys/dict/item/itemExtRecoverForClient', SysDictItemExtDto);
  }

  /**
  * 字典值分页查询
  * @param {TkApi.SysDictItemExtReq} SysDictItemExtReq - request
  */
  static itemPageForClientUsingPOST(
    SysDictItemExtReq:TkApi.SysDictItemExtReq,

  ) {
    return request.post<TkApi.PageVoSysDictItemVo>('/meta/v1/sys/dict/item/itemPageForClient', SysDictItemExtReq);
  }

  /**
  * itemList
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} dictCode - 字典类型
  * @param {number} dictId - 字典id
  * @param {string} dictLabel - 字典标签
  * @param {string} isDefault - 是否默认（Y是 N否）
  * @param {string} orderBy - 排序字段
  * @param {number} pages -
  * @param {string} records0DictCode - 字典类型
  * @param {number} records0DictId - 字典id
  * @param {string} records0DictLabel - 字典值名称
  * @param {number} records0DictSort - 字典排序
  * @param {string} records0DictValue -
  * @param {number} records0Id - 主键id
  * @param {string} records0Remark - 备注
  * @param {number} records0Status - 状态（1正常 0停用）
  * @param {string} scrollId -
  * @param {number} size - 一页几条
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} total -
  */
  static itemListUsingGET(params?: {
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            dictCode?:string,
                            dictId?:number,
                            dictLabel?:string,
                            isDefault?:string,
                            orderBy?:string,
                            pages?:number,
                            records0DictCode?:string,
                            records0DictId?:number,
                            records0DictLabel?:string,
                            records0DictSort?:number,
                            records0DictValue?:string,
                            records0Id?:number,
                            records0Remark?:string,
                            records0Status?:number,
                            scrollId?:string,
                            size?:number,
                            status?:number,
                            total?:number,

                }) {
    return request.get<Array<TkApi.SysDictItemVo>>('/meta/v1/sys/dict/item/list',
      params);
  }

  /**
  * itemPage
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} dictCode - 字典类型
  * @param {number} dictId - 字典id
  * @param {string} dictLabel - 字典标签
  * @param {string} isDefault - 是否默认（Y是 N否）
  * @param {string} orderBy - 排序字段
  * @param {number} pages -
  * @param {string} records0DictCode - 字典类型
  * @param {number} records0DictId - 字典id
  * @param {string} records0DictLabel - 字典值名称
  * @param {number} records0DictSort - 字典排序
  * @param {string} records0DictValue -
  * @param {number} records0Id - 主键id
  * @param {string} records0Remark - 备注
  * @param {number} records0Status - 状态（1正常 0停用）
  * @param {string} scrollId -
  * @param {number} size - 一页几条
  * @param {number} status - 状态（1正常 0停用）
  * @param {number} total -
  */
  static itemPageUsingGET(params?: {
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            dictCode?:string,
                            dictId?:number,
                            dictLabel?:string,
                            isDefault?:string,
                            orderBy?:string,
                            pages?:number,
                            records0DictCode?:string,
                            records0DictId?:number,
                            records0DictLabel?:string,
                            records0DictSort?:number,
                            records0DictValue?:string,
                            records0Id?:number,
                            records0Remark?:string,
                            records0Status?:number,
                            scrollId?:string,
                            size?:number,
                            status?:number,
                            total?:number,

                }) {
    return request.get<TkApi.PageVoSysDictItemVo>('/meta/v1/sys/dict/item/page',
      params);
  }

  /**
  * listByType
  * @param {string} dictCode - dictCode
  */
  static listByTypeUsingGET(
    dictCode:string,

  ) {
    return request.get<Array<TkApi.SysDictItemVo>>(`/meta/v1/sys/dict/item/type/${dictCode}`);
  }

  /**
  * itemGet
  * @param {number} dictCode - dictCode
  */
  static itemGetUsingGET(
    dictCode:number,

  ) {
    return request.get<TkApi.SysDictItemVo>(`/meta/v1/sys/dict/item/${dictCode}`);
  }

  /**
  * 字典值批量删除
  * @param {string} ids - ids
  */
  static itemDeleteUsingDELETE(
    ids:string,

  ) {
    return request.delete<TkApi.SysDictItemVo>(`/meta/v1/sys/dict/item/${ids}`);
  }

  /**
  * 条件查询
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} dictCode - 字典类型
  * @param {string} dictName - 字典名称
  * @param {string} orderBy - 排序字段
  * @param {number} size - 一页几条
  * @param {number} status - 状态（1正常 0停用）
  */
  static listUsingGET(params?: {
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            dictCode?:string,
                            dictName?:string,
                            orderBy?:string,
                            size?:number,
                            status?:number,

                }) {
    return request.get<Array<TkApi.SysDictVo>>('/meta/v1/sys/dict/list',
      params);
  }

  /**
  * 查询字典map
  */
  static listAllDictMapUsingGET(

  ) {
    return request.get<object>('/meta/v1/sys/dict/listAllDictMap');
  }

  /**
  * 分页查询
  * @param {number} current - 页码
  * @param {string} customizeOrderByList0Column -
  * @param {boolean} customizeOrderByList0Desc -
  * @param {string} customizeOrderByList0SortRule -
  * @param {boolean} desc - 是否倒序:true是,false否
  * @param {string} dictCode - 字典类型
  * @param {string} dictName - 字典名称
  * @param {string} orderBy - 排序字段
  * @param {number} size - 一页几条
  * @param {number} status - 状态（1正常 0停用）
  */
  static pageUsingGET(params?: {
                            current?:number,
                            customizeOrderByList0Column?:string,
                            customizeOrderByList0Desc?:boolean,
                            customizeOrderByList0SortRule?:string,
                            desc?:boolean,
                            dictCode?:string,
                            dictName?:string,
                            orderBy?:string,
                            size?:number,
                            status?:number,

                }) {
    return request.get<TkApi.PageVoSysDictVo>('/meta/v1/sys/dict/page',
      params);
  }

  /**
  * 保存或修改
  * @param {TkApi.SysDictDto} SysDictDto - request
  */
  static saveUsingPOST(
    SysDictDto:TkApi.SysDictDto,

  ) {
    return request.post<TkApi.SysDictVo>('/meta/v1/sys/dict/save', SysDictDto);
  }

  /**
  * 批量删除
  * @param {string} ids - 主键id
  */
  static deleteUsingDELETE(
    ids:string,

  ) {
    return request.delete<TkApi.SysDictVo>(`/meta/v1/sys/dict/${ids}`);
  }

  /**
  * 根据id查询
  * @param {number} id - 主键id
  */
  static getUsingGET(
    id:number,

  ) {
    return request.get<TkApi.SysDictVo>(`/meta/v1/sys/dict/${id}`);
  }
}
