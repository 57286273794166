import { SITE } from '@/constant/key';
import { defineStore } from 'pinia';
import store from 'store2';

interface SettingState {
  isCollapseMenu: boolean
  siteList: TkApi.ClientSiteAreaVo[],
  site: string
}

export const useSettingStore = defineStore({
  id: 'setting',
  state: (): SettingState => ({
    isCollapseMenu: false,
    siteList: [],
    site: '',
  }),
  getters: {
    getIsCollapseMenu(): boolean {
      return this.isCollapseMenu;
    },
    getSiteList(): TkApi.ClientSiteAreaVo[] {
      return this.siteList;
    },
  },
  actions: {
    setIsCollapseMenu(isCollapseMenu: boolean) {
      this.isCollapseMenu = isCollapseMenu;
    },
    setSiteList(siteList: TkApi.ClientSiteAreaVo[]) {
      if (siteList.length && !store(SITE)) {
        store(SITE, siteList[0].id);
      }
      this.siteList = siteList;
    },
    setSite(site: string) {
      this.site = site;
      store(SITE, site);
    },
  },
});
