import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import type { App } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { MotionPlugin } from '@vueuse/motion';
import contextmenu from 'v-contextmenu';
import 'v-contextmenu/dist/themes/default.css';

export const registerThirdComp = (app: App) => {
  app.use(VueTelInput);
  app.use(MotionPlugin);
  app.use(contextmenu);
  VueQueryPlugin.install(app, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    },
  });
};
