/**
 *  created by lllwx
 *  Date: 2021/6/1
 *  Time: 2:37 下午
 *  Version: 1.0
 *  For: table hooks
 */
import {
  reactive, Ref,
  toRefs,
} from 'vue';
import { HTTP_STATUS } from '../tools/enum';
import { usePage } from './usePage';
import { pageSetting } from '../tools/constant';

export interface IBaseOptions extends TKTable.ITableProps {
  pageSize: number;
  pageOptions: Partial<TKTable.Pagination>;
  form: any;
  // 是否立即调用请求
  lazyLoad: boolean;
  hasData: boolean;
  emptyText: string
  emptyRender?: any
  beforeFetch?: (params: any) => any
  customRow?:(record: any, index:number) => any
}

export interface IBasicTableOptions extends IBaseOptions {
  count: number;
  loading: boolean;
  dataSource: Record<string, any>[] | undefined
}

export declare type Service<T> = (...args: any) => Promise<{ code: HTTP_STATUS, result: any, message: string }>;

export const scroll = {
  scrollX: '',
};

export const tableConfig: Partial<IBaseOptions> = {
};

export function useTable<T>(options: Partial<IBaseOptions>): { tableProps: IBasicTableOptions }
export function useTable<T>(service: Service<T>, options?: Partial<IBaseOptions>):
  { tableProps: IBasicTableOptions, search: { submit: () => void, reset: () => void, refetch: () => void }, pageLimit: Ref<{pageSize: number, pageNo: number}>, }

export function useTable<T>(service: Service<T> | Partial<IBaseOptions>, options?: Partial<IBaseOptions>) {
  const baseOptions: IBaseOptions = {
    dataSource: [],
    pageSize: 10,
    bordered: true,
    pageOptions: {},
    pagination: true,
    form: null,
    lazyLoad: false,
    emptyText: '暂无数据',
    hasData: true,
    scroll: scroll.scrollX ? { x: scroll.scrollX } : {},
  };

  const tableProps = reactive<IBasicTableOptions>({
    ...baseOptions,
    ...tableConfig,
    ...(service instanceof Function ? {} : service),
    loading: false,
    dataSource: undefined,
    count: 0,
    ...(options ?? {}),
  });

  const { pageLimit, resetPageLimit } = usePage(runService, tableProps.pageSize);

  const search = {
    submit: () => { pageLimit.value[pageSetting.pageNo] = 1; runService(); },
    reset: resetPageAndForm,
    refetch: () => { runService(); },
  };

  tableProps.lazyLoad || runService();

  // 重置 分页参数
  function resetPageAndForm() {
    // 如果有分页 需要重置分页参数
    tableProps.pagination ? resetPageLimit() : runService();
  }

  async function runService() {
    // 没有传请求接口方法
    if (!(service instanceof Function)) return;
    const {
      loading, dataSource, count, form, pagination, beforeFetch,
    } = toRefs(tableProps);
    loading.value = true;
    try {
      let params = { ...form.value ? form.value : {} };
      // 是否有分页参数
      if (pagination?.value) {
        params = {
          ...params,
          ...pageLimit.value,
        };
      }
      // 查询前动态的参数
      if (beforeFetch?.value) {
        params = {
          ...params,
          ...beforeFetch.value(params),
        };
      }
      const { result } = await service(params);
      // 是否 分页数据
      if (result instanceof Array) {
        tableProps.hasData = !!result.length;
        dataSource.value = result as any[];
      } else {
        // 总条数为0 表示 没有数据
        tableProps.hasData = !!result.totalCount;
        dataSource.value = result.records as any[];
        count.value = result.total;
      }
    } finally {
      loading.value = false;
    }
  }

  // 表格第一个参数是查询接口方法
  if (service instanceof Function) {
    return {
      search,
      tableProps,
      pageLimit,
    };
  }

  // 表格只有一个选项参数
  return {
    tableProps: reactive({
      ...tableProps,
      ...service,
    }),
  };
}
