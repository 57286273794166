/**
 *  created by lllwx
 *  @date: 2022/7/29
 *  @time: 14:25
 *  @version: 1.0
 *  @desc:
 */

export enum HTTP_STATUS {
    MANAGE_SUCCESS,
    SUCCESS = 200,
    CREATED,
    ACCEPTED,
    AUTHENTICATE = 301,
    CLIENT_ERROR = 400,
    UNAUTHORIZED=401,
    FORBIDDEN = 403,
    NOT_FOUND,
    SERVER_ERROR = 500,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE,
    GATEWAY_TIMEOUT,
    CUSTOM_SUCCESS = 2000,
}

// 表格搜索参数类型
export enum SEARCH_PARAM_TYPE {
    INPUT,
    INPUT_NUMBER,
    SELECT,
    DATE,
    DATE_RANGE,
    Cascader,
    MONTH_RANGE,
    MONTH_PICKER,
    RENDER,
    DATE_RANGE_IRT,
    CHECKBOX,
}

export enum CONTENT_TYPE_ENUM {
  JSON = 'application/json',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded',
  // form-data  upload
  FORM_DATA = 'multipart/form-data',
}

// 通用状态枚举
export enum GENERAL_STATUS_ENUM {
  UN_ACTIVE,
  ACTIVE
}

// 菜单类型
export enum MENU_TYPE_ENUM {
  // 一级菜单
  PRIMARY_LEVEL,
  SUB_LEVEL,
  // 按钮
  BUTTON
}

// 菜单授权策略
export enum MENU_PERMS_TYPE_ENUM {
  // 可见
  VISIBLE = '1',
  // 可编辑
  EDITABLE = '2',
}

// 菜单状态
export enum MENU_STATUS_ENUM {
  // 有效
  VALID = '1',
  // 无效
  INVALID = '0',
}
