/**
 *  created by lllwx
 *  Date: 2021/6/1
 *  Time: 2:41 下午
 *  Version: 1.0
 *  For: 分页
 */
import { ref, watch, unref } from 'vue';
import { pageSetting } from '../tools/constant';

export function usePage(queryList: () => Promise<any>, pageSize = 10) {
  const basePage = 1;

  const pageLimit = ref({
    [pageSetting.pageSize]: basePage,
    [pageSetting.pageNo]: 1,
  });

  pageLimit.value[pageSetting.pageSize] = pageSize;

  watch(() => pageLimit.value[pageSetting.pageNo], () => {
    queryList();
  });

  watch(() => pageLimit.value[pageSetting.pageSize], () => {
    if (pageLimit.value[pageSetting.pageNo] !== 1) {
      pageLimit.value[pageSetting.pageNo] = 1;
    } else {
      queryList();
    }
  });

  // 重置分页条件
  function resetPageLimit() {
    if (unref(pageLimit)[pageSetting.pageNo] === basePage && unref(pageLimit)[pageSetting.pageSize] === pageSize) {
      queryList();
    } else {
      pageLimit.value[pageSetting.pageNo] = basePage;
      pageLimit.value[pageSetting.pageSize] = pageSize;
    }
  }

  return {
    pageLimit,
    resetPageLimit,
  };
}
