/**
 *  created by wenxiao.li
 *  Date: 2021/6/8
 *  Time: 9:52
 *  Version: 1.0
 *  For: basic form
 */
import {
  ref, unref, onUnmounted, watch, isRef, Ref, isReadonly,
} from 'vue';
import { formatDate } from '../utils';
import { toRefs } from '@vueuse/core';

const layoutColMap = new Map<string, any>()
  .set('horizontal', {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  })
  .set('inline', {
    labelCol: { span: 20 },
    wrapperCol: { span: 20 },
  });

export function useForm(options: TKForm.IFormProps): { formProps: Ref<TKForm.IFormProps>,
  methods: TKForm.IFormAction; initForm: (instance: any) => void } {
  // 表单默认props
  const baseProps: TKForm.IFormProps = {
    model: {},
    schemas: [],
    layout: 'horizontal',
    readonly: false,
  };
  // 合并默认和设置的props
  const formProps = ref<TKForm.IFormProps>({
    ...baseProps,
    ...options,
    schemas: unref(options.schemas),
  });
  // 设置默认componentProps
  formProps.value.schemas.forEach((schema) => {
    if (!schema.componentProps) schema.componentProps = {};
  });

  formProps.value = {
    ...toRefs(formProps.value),
    ...layoutColMap.get(formProps.value.layout ?? ''),
    ...options,
  };

  // 表单配置项可以监听 并且 不能是只读项 eg: computed 只有getter
  if (isRef(options.schemas) && !isReadonly(options.schemas)) {
    watch<TKForm.IFormSchema[]>(options.schemas, (value) => {
      formProps.value.schemas = value.filter((schema) => !schema.isHidden);
    });
  }

  function initForm(instance: any) {
    if (unref(formRef) === instance) return;
    formRef.value = instance;
    setDefaultValue();
  }

  onUnmounted(() => {
    formRef.value = null;
  });

  const formRef: Ref = ref(null);

  function getForm() {
    return unref(formRef) as unknown as any;
  }

  const setDefaultValue = () => {
    const schemas = unref(options.schemas);
    const keys = Object.keys(formProps.value.model);
    // model没有初始化的设置默认值
    schemas.forEach((schema) => {
      if (!keys.includes(schema.field)) {
        formProps.value.model[schema.field] = schema.defaultValue;
      }
    });
  };

  const methods = {
    getSchemas: () => formProps.value.schemas,
    getSchema: (field: string) => formProps.value.schemas.find((_) => _.field === field),
    updateSchema: (data: Partial<TKForm.IFormSchema> | Partial<TKForm.IFormSchema>[]) => {
      if (data instanceof Array) {
        data.forEach((_) => {
          if (!_.componentProps) _.componentProps = {};
        });
        formProps.value.schemas = data as TKForm.IFormSchema[];
      } else {
        const index = formProps.value.schemas.findIndex((_) => _.field === data.field);
        if (index !== -1) {
          formProps.value.schemas[index] = {
            ...formProps.value.schemas[index],
            ...data,
          };
        }
      }
    },
    clearValidate: (name: string | string[]) => {
      getForm().clearValidate(name);
    },
    resetFields: () => {
      getForm().resetFields();
    },
    removeSchemaByFiled: (field: string | string[]) => {
      // 过滤掉需要移除的schema
      formProps.value.schemas = formProps.value.schemas.filter((item) => !(item.field === field || field.includes(item.field)));
    },
    getFieldValue: (field: string) => formProps.value.model[field],
    getFieldsValue: () => formProps.value.model,
    setFieldValue: (field: string, value: any) => {
      formProps.value.model[field] = value;
    },
    setFieldsValue: (values: Record<string, any>) => {
      formProps.value.model = values;
    },
    appendSchemaByField: (schema: TKForm.IFormSchema, prefixField?: string | undefined) => {
      formProps.value.schemas.push(schema);
    },
    validate: async () => await getForm()?.validate(),
    submit: async (): Promise<any> => {
      // 提交表单
      try {
        await getForm()?.validate();
        return handleSubmitForm();
      } catch (e) {
        // 表单提交失败 阻止加载状态
        // @ts-ignore
        window.event && (window.event.target.canSubmit = false);
        throw new Error((e ?? '') as string);
      }
    },
    validateFields: async (fieldList: string[]) => await getForm().validateFields(fieldList),
  };

  // 处理要提交的表单数据
  function handleSubmitForm() {
    // 所有表单项
    const { model } = formProps.value;
    unref(unref(formProps).schemas).forEach((schema) => {
      // 处理range picker
      if (schema.component === 'RangePicker') {
        const rangeValue = model[schema.field];
        // rangeValue 通过fieldMap 映射
        if (rangeValue && rangeValue.length) {
          rangeValue.forEach((value: any, index: number) => {
            if (schema.fieldMap) {
              model[schema.fieldMap[index]] = new Date(value).getTime();
            }
          });
        }
      }
      // 如果是日期组件 并且没有设置格式化 处理时间
      if (schema.component === 'DatePicker' && !schema.componentProps.valueFormat) {
        model[schema.field] = formatDate(model[schema.field]);
      }
      if (schema.component === 'Cascader') {
        const value = model[schema.field];
        if (schema.fieldMap) {
          schema.fieldMap.forEach((_, index) => {
            model[_] = value[index];
          });
        }
      }
      // 文字输入去掉前后空格
      if (schema.component === 'Input' || schema.component === 'InputTextArea') {
        model[schema.field] = model[schema.field] && typeof model[schema.field] === 'string' ? model[schema.field].trim() : model[schema.field];
      }
    });
    return model;
  }

  return {
    formProps,
    initForm,
    methods,
  };
}
