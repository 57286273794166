// 树结构拍平
export function convertToFlat(treeData) {
  const flatData: any[] = [];
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].children) {
      flatData.push(...convertToFlat(treeData[i].children));
      delete treeData[i].children;
    }
    flatData.push({ ...treeData[i] });
  }
  return flatData;
}
