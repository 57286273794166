import type { App } from 'vue';
import { setupHasAuthDirective } from './button-auth';
import { setupHasLoadingDirective } from './loading';
import { setupSignAuthDirective } from './button-signature';

export function setupGlobDirectives(app: App) {
  setupHasAuthDirective(app);
  setupHasLoadingDirective(app);
  setupSignAuthDirective(app);
}
