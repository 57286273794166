import { getUserSystemInfo } from '@/router/system-info';
import { generateAsyncRoute } from './generate';
import { pendingRequest } from '@/utils/request';
import nprogress from 'nprogress';
import { createWebHistory, RouteRecordRaw, createRouter } from 'vue-router';
import type { App } from 'vue';
import { basicRoutes } from './basic';

const modules = import.meta.glob('./modules/**/*.ts', { eager: true });

const routeModuleList: RouteRecordRaw[] = [];

// 开发模式可以加载定义的路由
if (import.meta.env.MODE === 'development') {
  // 加载各模块路由
  Object.keys(modules).forEach((key) => {
    // @ts-ignore
    const mod = modules[key].default || {};
    const modList = Array.isArray(mod) ? [...mod] : [mod];
    routeModuleList.push(...modList);
  });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */'@/views/index.vue'),
    name: 'Index',
    children: [
      ...basicRoutes,
      ...routeModuleList,
    ],
  },
];

// 白名单：登陆，用户激活
export const WHITE_NAME_LIST: string[] = ['Login'];

// app router
const createNewRouter = () => createRouter({
  history: createWebHistory('/'),
  routes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

export const router = createNewRouter();

// 是否刷新页面
let isRefresh = true;

router.beforeEach(async (to, from, next) => {
  nprogress.start();
  // 白名单路径
  if (!WHITE_NAME_LIST.includes(to.name as string)) {
    await generateAsyncRoute();
    await getUserSystemInfo();
  }
  // 添加异常错误页面
  addError();
  if (isRefresh) {
    next({ path: to.fullPath, query: to.query, params: to.params });
    isRefresh = false;
  } else {
    next();
  }
  // // 取消上个页面未完成的请求
  pendingRequest.forEach((item) => item.routeChangeCancel && item.cancel());
  // // 清空pending请求
  pendingRequest.length = 0;
  nprogress.done();
});

// config router
export function setupRouter(app: App<Element>) {
  app.use(router);
}

function addError() {
  if (router.hasRoute('403') && router.hasRoute('404')) return;
  router.addRoute({
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "403" */'@/views/error/403.vue'),
  });
  router.addRoute({
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */'@/views/error/404.vue'),
  });
  router.addRoute({ path: '/:catchAll(.*)', redirect: '/404' });
}
